<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small"
                        @click="config.isDetailDisplay = false">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="editApi"
                        v-if="!config.isAdd && config.isCanEdit" v-right-code="'Apiinfo:Getemptyapi'">编辑</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="saveApi" v-if="config.isAdd" v-right-code="'Apiinfo:Getemptyapi'">保存</el-button>
                </div>
            </div>
        </div>

        <el-tabs type="border-card" v-model="activeName">
            <el-tab-pane name="tabbasic">
                <span slot="label">基本信息</span>
                <div class="form-list">
                    <el-form :model="dataSource" ref="_apiInfoForm" :rules="apiValidateRule">
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>接口编码：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ApiCode">
                                    <el-input v-model="dataSource.ApiCode" :maxlength="250" :minlength="2"
                                        placeholder="接口编码" :disabled="!config.isAdd || dataSource.IsSystemApi"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">接口名称：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ApiName">
                                    <el-input v-model="dataSource.ApiName" :maxlength="100" :minlength="2"
                                        placeholder="接口名称：" :disabled="!config.isAdd || dataSource.IsSystemApi"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>请求方式：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="HttpMethod">
                                    <DictionarySelect v-model="dataSource.HttpMethod" dicTypeCode="HttpMethodCode"
                                        :disabled="!config.isAdd || dataSource.IsSystemApi">
                                    </DictionarySelect>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>内容格式：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ContentType">
                                    <el-input v-model="dataSource.ContentType" :maxlength="100" :minlength="2"
                                        placeholder="Content-Type"
                                        :disabled="!config.isAdd || dataSource.IsSystemApi"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>所属系统：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExternalSystemId">
                                    <LookPopup v-model="dataSource.ExternalSystemName" dataTitle="系统选择"
                                        :dataUrl=externalSystemListUrl syncField='ExternalSystemName'
                                        @syncFieldEvent="syncSystemEvent" v-if="config.isAdd && !dataSource.IsSystemApi">
                                    </LookPopup>
                                    <span v-else>{{ dataSource.ExternalSystemName }}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                接口路径：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ApiPath">
                                    <el-input v-model="dataSource.ApiPath" :maxlength="256" :minlength="2"
                                        placeholder="接口路径" :disabled="!config.isAdd || dataSource.IsSystemApi"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                接口流向：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="DataFlow">
                                    <DictionarySelect v-model="dataSource.DataFlow" dicTypeCode="DataFlowCode" datType="Int"
                                        :disabled="!config.isAdd || dataSource.IsSystemApi">
                                    </DictionarySelect>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>接口状态：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ApiStatus">
                                    <DictionarySelect v-model="dataSource.ApiStatus" dicTypeCode="CommonStatusCode"
                                        datType="Int" :disabled="!config.isAdd">
                                    </DictionarySelect>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-show="dataSource.DataFlow==1">
                            <el-col :span="3" class="form-title">接口执行方式：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExecuteWay">
                                    <DictionarySelect v-model="dataSource.ExecuteWay" dicTypeCode="ExecuteWayCode"
                                        datType="Int" :disabled="!config.isAdd" >
                                    </DictionarySelect>
                                </el-form-item>
                            </el-col>

                            <el-col :span="3" class="form-title" v-show="dataSource.ExecuteWay==2">
                                <span style="color:red;">*</span>透传接口：
                            </el-col>
                            <el-col :span="7" v-show="dataSource.ExecuteWay==2">
                                <el-form-item prop="TransferApiId">
                                    <LookPopup v-model="dataSource.UseFunction" dataTitle="透传接口选择"
                                        dataUrl='omsapi/apiinfo/getReceiveApiPageList' syncField='TransferApiId'
                                        @syncFieldEvent="syncTransferApiEvent" v-if="config.isAdd && !dataSource.IsSystemApi">
                                    </LookPopup>
                                    <span v-else>{{ dataSource.UseFunction }}</span>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                请求实体：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="RequestObject">
                                    <el-select v-model="dataSource.RequestObject" filterable collapse-tags
                                        placeholder="请选择实体" @change="objectSourceChange"
                                        :disabled="!config.isAdd || dataSource.IsSystemApi">
                                        <el-option v-for="item in apiEntityList" :key="item.EntityCode"
                                            :label="item.EntityName" :value="item.EntityCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                返回实体：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ResponseObject">
                                    <el-select v-model="dataSource.ResponseObject" filterable collapse-tags
                                        placeholder="请选择实体" @change="objectSourceChange"
                                        :disabled="!config.isAdd || dataSource.IsSystemApi">
                                        <el-option v-for="item in apiEntityList" :key="item.EntityCode"
                                            :label="item.EntityName" :value="item.EntityCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- <el-tabs class="done" type="border-card" v-model="tabCommonParam">
            <el-tab-pane name="tabcommonparam">
                <span slot="label">公共参数</span>
            </el-tab-pane>
        </el-tabs> -->
        <el-tabs type="border-card" v-model="tabRequestBody">
            <el-tab-pane label="参数设置" name="tabrequest">
                <el-collapse v-model.trim="collapseItem">
                    <el-collapse-item title="请求参数" name="requestParamsInfo">
                        <el-tabs type="card" v-model="tabRequestBodyItem">
                            <el-tab-pane label="请求体" name="tabrequestbody">
                        <ApiParamConfig :paramsList="dataSource.RequestParamList" :entityName="dataSource.RequestObject"
                            :isCanEdit="config.isAdd" :transferApiId="dataSource.TransferApiId">
                        </ApiParamConfig>
                            </el-tab-pane>
                            <el-tab-pane label="请求头部" name="tabrequestheader">
                        <ApiParamConfig :paramsList="dataSource.HeaderParamList" :entityName="dataSource.RequestObject" :positionType="1"
                            :isCanEdit="config.isAdd" :transferApiId="dataSource.TransferApiId">
                        </ApiParamConfig>
                            </el-tab-pane>
                            <el-tab-pane label="Query参数" name="tabrequestquery">
                                <ApiParamConfig :paramsList="dataSource.QueryParamList" :entityName="dataSource.RequestObject" :positionType="2"
                            :isCanEdit="config.isAdd" :transferApiId="dataSource.TransferApiId">
                        </ApiParamConfig>
                            </el-tab-pane>
                        </el-tabs>
                    </el-collapse-item>
                    <el-collapse-item title="返回参数" name="responseParamsInfo">
                        <ApiParamConfig :paramsList="dataSource.ResponseParamList" :entityName="dataSource.ResponseObject"
                            :positionType="4" :isCanEdit="config.isAdd"  :transferApiId="dataSource.TransferApiId">
                        </ApiParamConfig>
                    </el-collapse-item>
                </el-collapse>
            </el-tab-pane>
        </el-tabs>
        <!-- <el-tabs type="border-card" v-model="tabResponseBody">
            <el-tab-pane name="tabresponse">
                <span slot="label">返回参数</span>
            </el-tab-pane>
        </el-tabs> -->
    </div>
</template>
<script>
export default {
    mounted() {
        this.getApiEntitys();
    },
    data() {
        return {
            externalSystemListUrl: window.AppConfig.apiBaseUrl + 'omsapi/sysexternalsystem/searchexternalsystemlist',
            activeName: "tabbasic",
            tabCommonParam: "tabcommonparam",
            tabRequestBody: "tabrequest",
            tabRequestBodyItem: "tabrequestbody",
            tabResponseBody: "tabresponse",
            collapseItem: ["requestParamsInfo", "responseParamsInfo"],
            apiValidateRule: {
                ApiCode: [{ required: true, message: '请输入接口编码', trigger: 'blur' }],
                HttpMethod: [{ required: true, message: '请选择请求方式', trigger: 'blur' }],
                ContentType: [{ required: true, message: '请输入内容格式', trigger: 'blur' }],
                ExternalSystemId: [{ required: true, message: '请选择所属系统', trigger: 'blur' }],
                //ApiPath: [{ required: true, message: '请输入接口路径', trigger: 'blur' }],
                ApiStatus: [{ required: true, message: '请选择接口状态', trigger: 'blur' }],
            },
            apiEntityList: [],
            fieldSelectOptions: [],
            dataSource: {

            },
        };
    },
    props: {
        config: {
            isDetailDisplay: false,
            dataSourceKey: null,
        },
    },
    watch: {
        'config.dataSourceKey': {
            handler(curVal, oldVal) {
                if (curVal && curVal > 0) {
                    if (curVal != oldVal) this.getDetail(curVal); //初始化
                }
            },
            deep: true
        },
    },
    methods: {
        addApi() {
            var _this = this;
            this.$ajax.send("omsapi/apiinfo/getemptyapi", "get", {}, (data) => {
                _this.dataSource = data.Result;
            });
        },
        editApi() {
            this.config.isAdd = true;
        },
        detailApi(apiId) {
            this.config.isAdd = false;
        },
        getDetail(apiId) {
            var _this = this;
            this.$ajax.send("omsapi/apiinfo/get", "get", { id: apiId }, (data) => {
                _this.dataSource = data.Result;
                // _this.$nextTick(function () {
                // });
            });
        },
        saveApi() {
            this.$refs["_apiInfoForm"].validate((valid) => {
                if (valid) {
                    var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
                    var obj = this.Utils.cloneObj(this.dataSource);
                    this.$ajax.send("omsapi/apiinfo/" + actionName, "post", obj, (data) => {
                        this.config.dataSourceKey = data.Result;
                        this.config.isCanEdit = false;
                        this.config.isAdd = false;
                        //this.detailApi(curVal)
                        this.Event.$emit("reloadRolePageList");
                        this.Utils.messageBox("保存成功.", "success");
                    });
                } else {
                    this.Utils.messageBox("存在必填信息未输入.", "error");
                    return false;
                }
            });
        },
        syncSystemEvent(row) {
            if (row) {
                this.dataSource.ExternalSystemId = row.ExternalSystemID;
                this.dataSource.ExternalSystemName = row.ExternalSystemName;
            }
        },
        syncTransferApiEvent(row){
            if (row) {
                console.log(row)
                this.dataSource.TransferApiId = row.Id;
                this.dataSource.UseFunction = row.ExternalSystemName+":"+row.ApiCode+row.ApiName;
                this.dataSource.RequestObject=row.RequestObject;
                this.dataSource.ResponseObject=row.ResponseObject;
            }
        },
        // executeWayChange(val){
        //     if(val != 2){
        //         this.dataSource.TransferApiId = null;
        //         this.dataSource.UseFunction = null;
        //     }
        // },
        getApiEntitys() {
            var _this = this;
            _this.$ajax.send("omsapi/apiinfo/getApiEntityList", "get", {}, (data) => {
                if (data.IsSuccess) {
                    _this.apiEntityList = data.Result;
                }
            });
        },
        objectSourceChange(val) {
        },
    },
    components: {
        "ApiParamConfig": resolve => { require(['../../../../business/system/apiconfig/components/apiparamconfig'], resolve) },
    }
}
</script>